.contact__wrapper{
	display: inline-block;
	.map{
		display: inline;
		float:left;
		width:490px;
		height:355px;
	}
	.address{
		display: inline;
		float:left;
		position: relative;
		width:400px;
		max-width:100%;
		height:355px;
		padding-left:45px;
		p{
			position: absolute;
			bottom: 0;
		}
	}
}


// responsive
@include break (1360){
}
@include break (1024){

}
@include break (980){
}
@include break (768){
	.contact__wrapper{
		.map{ display: none; }
		.address{
			width:initial;
			height:initial;
			padding-left:0;
			p{ position: relative; }
		}
	}
}
@include break (480){
}
@include break (320){
}


