a,input[type='submit'],button{ 
    position:relative;
    display: inline-block;
	border: none; outline: none;	
    &.btn--main{        
        font-size: 14px;
        font-family:'proximanova.bold';
        letter-spacing: 1.5px;
        text-decoration:none !important;
        line-height: normal;
		padding: 12px 30px;
        color:#fdfdfd;
        transition:0.3s ease;
        background:$grey6;
        margin:0 5px;
        &:hover{
            background:#000;
        }
        &__small{
            font-family:'proximanova.semibold';
            font-size: 13px;
            letter-spacing: 1px;
            text-decoration:none !important;
            line-height: normal;
            color:#fdfdfd;
            padding: 8px 12px;
            background:$grey6;
            transition:0.3s ease;
            border:1px solid $grey6;
            margin:0 5px;
            &:hover{
                background:#000;
            }
        }
    }
    &.btn--box{
        font-family:'proximanova.bold';
        padding:10px 34px;
        font-size: 14px;
        text-decoration:none !important;
        line-height: normal;
        color:$grey6;
        border:1px solid $grey6;
        transition:0.3s ease;
        background:transparent;
        margin:0 5px;
        &:hover{
            color:white;
            background:$grey6;
        }
        &__small{
            font-family:'proximanova.semibold';
            padding:8px 12px;
            font-size: 13px;
            letter-spacing: 1px;
            text-decoration:none !important;
            line-height: normal;
            color:$grey6;
            border:1px solid $grey6;
            transition:0.3s ease;
            background:transparent;
            margin:0 5px;
            &:hover{
                color:white;
                background:$grey6;
            }
        }
    }
    &.btn--text{
        font-family:'proximanova.regular';
        font-size:13px;
        text-decoration:none !important;
        line-height: normal;
        color:$grey5;
        margin:0 3px;
        padding:8px 15px;
        &:hover{
            text-decoration: underline;
        }
    }
    &.btn--white{
        display: block;
        padding:22px;
        text-decoration:none !important;
        &:hover{ background:#f8f8f8; }
    }
}

@include break (768){
    a,input[type='submit'],button{ 
        &.m-widthfull{ width:100% !important; text-align: center; margin:0; padding-left:0 !important; padding-right:0 !important; }
        &.btn--main{        
            font-size: 13px;
            &__small{ font-size: 12px; padding:9px 8px 8px 8px; }
        }
        &.btn--box{
            font-size: 13px;
            &__small{ font-size: 12px; padding:9px 8px 8px 8px; }
        }
        &.btn--text{ font-size:12px; }
    }
}