@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform:translateY(0); }
  40% { transform:translateY(-10px); }
  60% { transform:translateY(-5px); }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(1.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -10deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  100%{
    transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px);
  }
}

@keyframes hrscalelr {
  0% {
    opacity: 0;
    width:0;
    background:#fff;
  }
  100% {
    opacity: 1;
    width:100px;
    background:#fff; 
  }
}

@keyframes fadeInright {
  0% {
    opacity: 0;
    // transform: rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    opacity: 1;
    // transform: rotate(360deg);
    transform: translate(-80px, 0px) rotate(-360deg);
  }
}

@keyframes fromTop {
  0%{ top:-30px; opacity:0; visibility:hidden; }
  100%{ top:0px; opacity:1; visibility:visible; }
}

@keyframes lol {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  66% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(150px);
  }
}


@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}