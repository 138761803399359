.footer{
	background:$cream;
	padding-top:20px;
	&__wrapper{
		display: inline-block;
		padding:20px 0;
	}
	&__left,
	&__right{
		display: inline;
		float:left;
	}
	&__logo-fs{
		display: inline;
		float:left;
		width:200px;
	}	
	&__ul{

	}
	&__li{
		width:135px;
		padding:0 15px;
		text-align: left;
		small{
			display: block;
		}
		// &:last-child{
		// 	position: absolute;
		//     top: 150px; right: 180px;
		// }
	}
	&__title{
		margin-bottom:10px;
	}
	&-link{
		&__ul{

		}
		&__li{			
			width: 100%;
			margin-bottom:5px;			
		}
		&__a{
			font-size:14px;
			letter-spacing: 0;
		}
	}
	&__copyright{

	}
	&__mcopyright{
		display: none;
		background:$grey6;
		color:white;
		padding:30px 0;
		text-align: center;
		h4{
			font-family: 'proximanova.light';
		    font-weight: normal;
		    letter-spacing: 1.5px;
		}
	}
	.subscribe{
		display: inline;
		float:left;
		width:300px;
		text-align: left;
		.input__wrapper{
			.input--small{
				width:180px;
				float:left;
			}
			.btn--main__small{
				
			}
		}
	}
	.supported{
		font-size: 13px;
		letter-spacing: 0.5px;
	}
}
/* responsive */
@include break (980){
	.footer{
		&__wrapper{
			width:calc(100% - 40px);
		}
		&__left,
		&__right{
			float:initial;
		}
		&__logo-fs{
			display: none;
		}
		&__li{
			&:last-child{
				position: relative;
				top: 0; right: 0;
			}
		}
		.subscribe{
			width:100%;
			h3{ display: none; }
			small{ display: block; font-size:20px; }
		}
	}
}

@include break (768){
	.footer{
		&__wrapper{
			br{ display: none; }
		}
		&__ul{ display: none; }
		&__copyright{ display: none; }
		&__mcopyright{
			display: block;
		}

		.footer__seo{
			padding: 0 10px;
		}
	}
}