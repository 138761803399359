.header{
  position:relative;
  z-index: 100;
  top:0;  
  width:100%;
  &--account{
    background:$cream;
    padding:5px 0;
  }
  &--navbar{
    background:$grey6;
    .container{
      display: flex;
      justify-content:space-between;
    }    
  }
  &--info{
    padding:6px 0;
    border-bottom:1px solid #ddd;
    h4{
      font-size: 15px;
    }
  }
  .header--logo{
    a{
      display: flex;
    }
  }
  .logotext{
    // margin:30px auto;
    // height: 33px;
    margin: 23px auto;
    height: 35px;
  }
  &--mobile{
    position: relative;
    display: none;
    height:50px;
    background:$cream;
    .mmenu{
      position: absolute;
      left:0;
      padding:16px 8px;
    }
    .mcart{
      position: absolute;
      right:0;
      padding:16px 8px;
    }
    .msearch{
      position: absolute;
      right:37px;
      padding:16px 8px;
    }
    .mlogotext{
      width:400px;
      margin:0 auto;
      padding:10px 0;
    }
  }
}

.navbar-account{
  display: inherit;
  float:right;
  margin-right: 20px;
  &__ul{
    display: block;
  }
  &__li{
    position: relative;
    padding:0 25px;
    font-family:'proximanova.regular';
    font-size: 14px;
    .header-cart{
      display: flex;
      align-items: center;
    }
    a{
      font-size: 12px;
    }
    // color:$grey6;
    &:last-child{ 
      padding: 0 0 0 25px;
      &:before{
        content: "";
        position: absolute;
        top: 4px; left: 0;
        width: 1px; height: 10px;
        background:$grey6;
      } 
    }
    &:first-child{ 
      padding: 0 25px 0 25px;
      &:after{
        content: "";
        position: absolute;
        top: 4px; right: 0;
        width: 1px; height: 10px;
        background:$grey6;
      } 
    }
    .cart-numb{
      font-family:'proximanova.bold';
      color:#525151;
    }
  }
  &__profile{
    &:hover{
      .sub-account{
        display: block;
      }
    }
  }
  &__name{
    position: relative;
    z-index: 50;
    border-bottom:1px solid #c2c2b4;
    display: block;
    max-width: 134px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.sub-account{
  display: none;
  position: absolute;
  z-index: 49;
  top:17px; left:10px;
  width:165px;
  background:$cream;
  &__ul{
    padding: 5px 15px 0 15px;
  }
  &__li{
    width:100%;
    margin-bottom: 5px;
    text-align: left; 
    &:first-child{
      border-bottom:1px solid $grey6;
      padding-top:10px;
    }
    &:last-child{
      border-top:1px solid $grey6;
      margin-bottom: 0;
    }
  }
  &__a{

  }
  &__p{
    font-family: 'proximanova.bold';
    font-size: 13px;
    padding:4px 0;
    letter-spacing: 0.6px;
  }
}

.navbar{  
  position: relative;
  &__ul{
    display: flex;
    justify-content:flex-start;
  }
  &__li{
    display: flex;
    align-items:center;    
    position: relative;
    height:36px;
    padding:0 30px;
    text-transform: uppercase;
    color:white;
    font-size: 16px;
    letter-spacing: 1px;
    white-space: nowrap;
    cursor: default;
    &:hover{
      color:$grey6;
      background:white;
      .navbar__sub{
        display: flex;
        justify-content:flex-start;
      }
    }
    a{ 
      font-size: 14px;
    }
  }
  .navbar__sub{
    display: none;
    position: absolute;
    left:0; top:36px;
    background:white;
    z-index: 1;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    &__ul{      
      bottom:0;
      width:185px;
      text-align: left;
      padding: 15px;
    }
    &__label{
      display: block;
      font-family:'proximanova.bold';      
      border-bottom:1px solid $grey6;
      margin-bottom: 5px;
      padding-bottom: 5px;
      color:$grey6;
      letter-spacing: 1px;
    }
    &__li{
      width:100%;
      padding:10px 0 0 0;
      text-transform: capitalize;
      letter-spacing: 0;
      ul{
        float:left;
      }      
    }
    &__a{

    }
  }
}

.search-navbar{  
  background:transparent !important;
  width:20px !important;
  height:36px !important;
  float: right;
  font-family:'proximanova.light';
  font-size:15px !important;
  padding: 4px 11px !important;
  color:white;
  border:none;  
  margin-right: 6px;
  transition:0.3s ease;
  border:1px solid transparent !important;
  cursor: pointer;
  & ~ label{
    cursor: pointer;
  }
  &:focus{
    width:310px !important;
    background:white !important;
    color:$grey6;
    margin-right: 0px;
    cursor: auto;
    & ~ label{
      cursor: default;
      .ico-search{
        @extend .ico-search-black;
        position:absolute; 
        top:0; right:10px; bottom:0; 
        margin:auto;
      } 
    }   
  }  
  &__wrapper{
    position: absolute;
    top:0; right:0;
    height:36px;
    .ico-search{
      @extend .ico-search-white;
      position:absolute; 
      top:0; right:10px; bottom:0; 
      margin:auto;
    }   
  }
}

// mnavbar
.mnavbar{  
  position: relative;
  width: 260px;  
  // overflow-x: hidden;
  background: $grey6;
  text-align: left;
  transform:translate(-300px, 0);
  transition:0.4s all;  
  &.active{
    transform:translate(0px, 0);
  }
  &__wrapper{
    visibility: hidden;
    opacity:0;
    position: fixed;
    top:0; left:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0, 0.4);
    z-index: 100;
    transition:0.4s all;
    &.active{
      visibility: visible;
      opacity: 1;
    }
    .arrowleft__wrapper{
      cursor: pointer;
      position: absolute;
      top:0; left:260px;
      background:white;
      padding:16px 20px;
    }    
  }
  &__ul{
    // width: 110%;
    height:100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &__li{  
    position: relative;
    width:100%;
    text-transform: uppercase;
    color:white;
    font-size: 16px;
    letter-spacing: 1px;
    &__account{
      background:$cream;
      color:$grey6;
    }
    a{ 
      font-size: 15px;
      padding: 15px 20px;
      width: 100%;
      border-bottom: 1px solid #565653;
    }
  }
  .mnavbar__sub{
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #000;
    &__ul{
      width:100%;
    }
    &__label{
      position: relative;
      display: block;
      font-family:'proximanova.bold';
      color:white;
      padding:20px 0;
      letter-spacing: 1px;
      padding: 10px 0 10px 40px;
      .ico-plus-mini,
      .ico-min-mini{
        position: absolute;
        top:0; right:0;
      }
    }
    &__li{
      display: none;
      width:100%;
      padding:0;
      text-transform: capitalize;
      letter-spacing: 0;
      background:#565653;      
      ul{
        float:left;
      }      
    }
    &__a{
      padding: 10px 0 10px 60px;
      border:none;
    }
  }
}

.msearchbar{
  position: relative;
  width: 100%; height:100vh;
  background: white;
  text-align: left;
  transform:translate(0, -30%);
  transition:0.4s all;  
  &.active{
    transform:translate(0, 0);
  }
  &__wrapper{
    visibility: hidden;
    opacity:0;
    position: fixed;
    top:0; left:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0, 0.4);
    z-index: 100;
    transition:0.4s all;
    &.active{
      visibility: visible;
      opacity: 1;
    }
  }
}

/* responsive */
@include break (1024){
  .header{
    &--account{ display: none; }
    &--navbar{ display: none; }
    &--logo{ display: none; }
    &--mobile{ display: block; }
    &--info{ 
      background:$grey6;
      border-bottom:0px solid;
      h4{ color:white; }
    }
  }
}

@include break (980){
  .navbar{
    &__li{
      padding:0 15px;
    }
  }
  .search-navbar{
    width:25px !important;
    margin-right: 0;
    position: relative;
    z-index: 10;
    padding: 14px 18px !important;
    &:focus{
      position: initial;    
    } 
  }
}

@include break (768){
  .header{
    &--info{
      h4{ font-size: 16px; letter-spacing: 1px; }
    }
  }
}

@include break (600){
  .header{
    &--mobile{
      .mlogotext{
        width:160px;
        padding:20px 0;
      }
    }
  }
}
@include break (480){
  .header{
    &--info{
      h4{ font-size: 14px; }
    }
  }
}