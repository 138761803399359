
/* Some basic styling to notify users
   about the tooltips*/
.tooltip {
  text-decoration: none;
  &__default{
    position: absolute;
    top:-40px; left:0;
    background:$cream;
    color:$grey6;
    padding:5px 10px;
    box-shadow: 2px 3px 10px #ccc;
    &:before{
      content: '';
      position: absolute;
      top: 100%;
      left:0; right:0;
      margin: 0px auto;
      width: 0; 
      height: 0;
      border-left: 6px solid transparent;
      border-top: 6px solid $cream;
      border-right: 6px solid transparent;    
    }
  }  
}
 
.tooltip:hover {
  position: relative;
}
 
/* Tooltip on Top */
.tooltip-top[data-tooltip]{
  &:hover{
    &:after{
      content: attr(data-tooltip);
      padding: 4px 8px;
      position: absolute;
      left: 0;
      bottom: 100%;
      white-space: nowrap;
      z-index: 20;
     
      background-color: #000;
      color: #fff;
    }
    &:before{
      content: '';
      position: absolute;
      top: 100%;
      left:0; right:0;
      margin: 0px auto;
      width: 0; 
      height: 0;
      border-left: 6px solid transparent;
      border-top: 6px solid #000;
      border-right: 6px solid transparent;    
    }
  }
  &:after,&:before{ transition: 0.3s all; }
}

/* Tooltip on Bottom */
.tooltip-bottom[data-tooltip]{
  &:hover{
    &:after {
      content: attr(data-tooltip);
      padding: 4px 8px;
      position: absolute;
      left: 0;
      top: 100%;
      white-space: nowrap;
      z-index: 20;
     
      background-color: #000;
      color: #fff;
    }
    &:before{
      content: '';
      position: absolute;
      top: 100%;
      left:0; right:0;
      margin: 0px auto;
      width: 0; 
      height: 0;
      border-left: 6px solid transparent;
      border-top: 6px solid #000;
      border-right: 6px solid transparent;
      transform: rotate(180deg);
    }
  }
  &:after,&:before{ transition: 0.3s all; }
}

/* Tooltip on Right */
.tooltip-right[data-tooltip]{
  &:hover{
    &:after {
      content: attr(data-tooltip);
      padding: 4px 8px;
      position: absolute;
      left: 100%;
      white-space: nowrap;
      z-index: 20;
     
      background-color: #000;
      color: #fff;
    }
    &:before{
      content: '';
      position: absolute;
      top: 100%;
      left:100%;
      width: 0; 
      height: 0;
      border-left: 6px solid transparent;
      border-top: 6px solid #000;
      border-right: 6px solid transparent;
      transform: rotate(90deg);
    }
  }
  &:after,&:before{ transition: 0.3s all; }
}

/* Tooltip on Left */
.tooltip-left[data-tooltip]{
  &:hover{
    &:after{
      content: attr(data-tooltip);
      padding: 4px 8px;
      position: absolute;
      right: 100%;
      white-space: nowrap;
      z-index: 20;
     
      background-color: #000;
      color: #fff;
    }
    &:before{
      content: '';
      position: absolute;
      top:100%;
      right:100%;
      width: 0; 
      height: 0;
      border-left: 6px solid transparent;
      border-top: 6px solid #000;
      border-right: 6px solid transparent;
      transform: rotate(-90deg);
    }
  }
  &:after,&:before{ transition: 0.3s all; }
}