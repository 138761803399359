.static_page{
	.img{
		position: relative;
		width: 350px;
		height: 0;
		padding-bottom: 40%;
		margin: auto;
		display: inline-block;
		img{
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}