.popup{
	&__wrapper{
		visibility: hidden;
		opacity:0;
		position: fixed;
		top: 0; left: 0;
		width: 100%; height: 100%;
		background: rgba(0,0,0, 0.4);
		z-index: 100;
		transition:0.4s all;
		display: flex;
		justify-content: center;
		align-items: center;
		&.active{
			visibility: visible;
			opacity: 1;
		}
	}
	&__box{
		position: relative;
		width: 380px;
		// margin: auto;
		padding: 35px;
		background: white;
		// &--logreg{ height: 230px; }
		// &--removesc{ height:80px; }
		// &--afteradditem{ height:65px; }
		// &--expiredcode{ height:130px; }
		&--minimumorder{
			width:600px;
			// height:170px;
			.form__wrapper{
				padding: 20px 0 0px 0;
			}
			.input__wrapper{
				margin: 15px 0 0px 0;
			}
		}
		&--afteradditem{
			.input__wrapper{
				display: flex;
			}
		}
	}
	&__content{
		position: relative;
		.ico-popupclose{
			cursor: pointer;
			position: absolute;
			top:-26px; right:-26px;
		}
		.form__wrapper{
			.input__wrapper{
				width:100%;
				margin:10px 0 15px 0;
			}
		}
		.input__wrapper{
			input{
				width: inherit;
			}
		}
	}
}

.popup__promo{
	width: 785px;
	max-width: 100%;
	height: 540px;
	max-height: 100%;
	margin: 0 auto;
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	.popup__container{
		// position: relative;
		width: 100%;
		height: 100%;

		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}
	.center{text-align: center;}
	.popup__promo__bg--desktop{
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;		
		position: absolute;
		top:0;
		left:0;
		z-index: 0;

		display: block;
	}
	.popup__promo__bg--mobile{
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		// background-position: center;		
		position: absolute;
		top:0;
		left:0;
		z-index: 0;
		
		display: none;
	}
	.popup__promo__content{
		width: 50%;
		padding: 50px 35px 0;
		position: relative;
		.subtitle{
			font-family: 'lora.italic';
			font-size: 30px;
		}
		.title{
			color: #f06c46;
			font-size: 22px;
			letter-spacing: 2px;
			display: block;
			min-height: 200px;			
			.big{
				font-family:'proximanova.bold';
				font-size: 72px;
				line-height: 72px;
			}
		}
		.field{
			margin-bottom: 10px;
		}
		input[type="text"]{
			border: 1px solid #000;
			background-color: #fff;
			padding: 5px;
			height: 50px;
			text-align: center;
			font-size: 20px;
			letter-spacing: 1.2px;
		}
		.btn-subscribe{
			display: block;
			background-color: #363636;
			height: 50px;
			font-size: 20px;
			color: #fff;
			width: 320px;
			max-width: 100%;
			margin: auto;
			text-transform: uppercase;
			letter-spacing: 1px;
			transition: .3s ease;
			&:hover{
				background-color: lighten(#363636, 5%);
			}
		}
		.link{
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
		.success-message{
			p{
				text-align: center;
				font-size: 20px;
				letter-spacing: 1.2px;
				line-height: 27px;
				margin: 0;
			}
		}
		.error-message{
			color: #d10000;
			margin: 0;
		}
		.error-state{
			input[type="text"]{
				color: #d10000;
				border: 1px solid #d10000;
				&::-webkit-input-placeholder {color: #d10000;}
				&:-moz-placeholder           {color: #d10000;}
				&::-moz-placeholder          {color: #d10000;}
				&:-ms-input-placeholder      {color: #d10000;} 
			}
		}
	}
	.mfp-close{
		font-size: 28px;
	}
	.row{
		margin-left: -10px;
		margin-right: -10px;
		&:before,
		&:after{
			display: table;
			line-height: 0;
			content: "";
		}
		&:after { clear: both; }
		.col{
			display: block;
			float: left;
			box-sizing: border-box;
			min-height: 5px;
			width: 100%;
			padding: 0 10px;
			&.span6{width: calc(100% / 2);}
		}
	}
}


/* PRELOADER IN LOGIN POPUP */
.cs-loader-inner {
  // transform: translateY(-50%);
  // top: 50%;
  // position: absolute;
  // width: calc(100% - 200px);
  // padding: 0 100px;
  color: #FFF;
  text-align: center;
}
.cs-loader-inner label {
  font-size: 20px;
  opacity: 0;
  display:inline-block;
}
.cs-loader-inner label:nth-child(6) {
  -webkit-animation: lol 3s infinite ease-in-out;
  animation: lol 3s infinite ease-in-out;
}
.cs-loader-inner label:nth-child(5) {
  -webkit-animation: lol 3s 100ms infinite ease-in-out;
  animation: lol 3s 100ms infinite ease-in-out;
}
.cs-loader-inner label:nth-child(4) {
  -webkit-animation: lol 3s 200ms infinite ease-in-out;
  animation: lol 3s 200ms infinite ease-in-out;
}
.cs-loader-inner label:nth-child(3) {
  -webkit-animation: lol 3s 300ms infinite ease-in-out;
  animation: lol 3s 300ms infinite ease-in-out;
}
.cs-loader-inner label:nth-child(2) {
  -webkit-animation: lol 3s 400ms infinite ease-in-out;
  animation: lol 3s 400ms infinite ease-in-out;
}
.cs-loader-inner label:nth-child(1) {
  -webkit-animation: lol 3s 500ms infinite ease-in-out;
  animation: lol 3s 500ms infinite ease-in-out;
}
/* END PRELOADER IN LOGIN POPUP */

@include break (768){
	.popup{
		&__box{
			width: 80%;	
			padding: 40px 0;
		}
		&__content{
			.ico-popupclose{ top:-30px; right:10px }
		}
	}

	.popup__promo{
		width: 402px;
		.popup__promo__bg--desktop{display: none;}
		.popup__promo__bg--mobile{display: block;}
		.popup__container{align-items: center;}
		.popup__promo__content{
			width: 100%;
			padding: 0px 10px 0;
		}
	}
}

@include break (600){
	.popup__promo{
		width: 345px;
		height: 480px;
		.popup__promo__content{
			.subtitle{
				font-size: 24px;
				margin-bottom: 15px;
			}
			.title{
				font-size: 25px;
				min-height: 110px;
				margin-bottom: 40px;
				.big{
					font-size: 60px;
					line-height: 60px;
				}
			}
			input[type="text"]{
				height: 40px;
				font-size: 16px;
				background-color: rgba(255,255,255,.3);
			}
			.btn-subscribe{
				height: 40px;
				font-size: 18px;
			}
		}
	}
}

@include break (480){
	.popup{
		&__box{ width: 100%;	}
	}
}