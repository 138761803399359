@mixin sprite($width, $height, $position, $margin) {
	background:url(/images/sprite.png) $position no-repeat;
	display: inline-block;
	width: $width;
	height: $height;
	margin: $margin;
	vertical-align: sub;
	// retina
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
		background:url(/images/sprite@2x.png) $position no-repeat;
		background-size:640px 80px;
	}
}

.logo-fs{@include sprite(468px, 36px, 0 -64px, 0 0 0 0)}
.logo-fs-green{@include sprite(144px, 120px, -480px -64px, 0 0 0 0)}

.ico-sandwitch{@include sprite(23px, 16px, -105px 0, 0 5px 0 0)}
.ico-cart{@include sprite(16px, 16px, 0 0, 0 5px 0 0)}
.ico-search-white{@include sprite(16px, 16px,-16px 0, 0 5px 0 0); }
.ico-search-black{@include sprite(16px, 16px,-32px 0, 0 5px 0 0); }
.ico-plus-black{ @include sprite(8px, 8px, -75px -18px, 5px 10px); }

.ico-instagram{@include sprite(16px, 16px, -48px 0, 0 5px 0 0)}
.ico-facebook{@include sprite(16px, 16px, -64px 0, 0 5px 0 0)}
.ico-bridestory{@include sprite(134px, 20px, 0 -60px, 10px 5px 0 0)}

.ico-flower{@include sprite(20px, 18px, -80px 0, 0 5px 0 0)}

.ico-atmbca{@include sprite(58px, 32px, -242px 0, 0 5px 0 0)}
.ico-atmmandiri{@include sprite(60px, 32px, -314px 0, 0 5px 0 0)}
.ico-cimbclicks{@include sprite(67px, 32px, -386px 0, 0 5px 0 0)}
.ico-mandiriclickpay{@include sprite(67px, 32px, -566px 0, 0 5px 0 0)}
.ico-visa{@include sprite(50px, 32px, -128px 0, 0 5px 0 0)}
.ico-mastercard{@include sprite(50px, 32px, -180px 0, 0 5px 0 0)}
.ico-permatabank{@include sprite(16px, 16px, -64px 0, 0 5px 0 0)}
.ico-veritrans{@include sprite(128px, 32px, -96px -32px, 0 5px 0 0)}

.ico-atmbca-big{@include sprite(77px, 24px, -224px -32px, 0 5px 0 5px);}
.ico-cimbclicks-big{@include sprite(108px, 18px, -304px -32px, 0 5px 0 0); vertical-align: middle;}
.ico-atmmandiri-big{@include sprite(101px, 30px, -416px -32px, 0 5px 0 5px); vertical-align: inherit;}
.ico-mandiriclickpay-big{@include sprite(75px, 32px, -528px -32px, 0 5px 0 0); vertical-align: middle;}

.ico-supportedveritrans{@include sprite(224px, 28px, 0 -32px, 0 5px 0 0); margin:5px auto 30px auto;}
.ico-support1{@include sprite(248px, 32px, -128px 0, 0 5px 0 0);}
.ico-support2{@include sprite(254px, 32px, -376px 0, 0 5px 0 0);}

.ico-arrowleft-mini{ @include sprite(9px, 16px, 0 -16px, 0); }
.ico-plus-mini{ @include sprite(10px, 10px, -10px -16px, 10px 15px 10px 10px); background-size: 552px;}
.ico-min-mini{ @include sprite(10px, 10px, -26px -16px, 10px 15px 10px 10px); background-size: 552px;}

.ico-search2 { @include sprite(16px, 16px, -59px -16px, 10px); }
.ico-remove-circle { @include sprite(16px, 16px, -42px -16px, 10px); }
.ico-popupclose { @include sprite(10px, 10px, -135px -64px, 0); }

.fa-check-circle{color:#39b54a;}


.ico-plus-mini{
	background: url(/images/sprite.png) -10px -16px no-repeat;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 10px 15px 10px 10px;
    vertical-align: sub;
    background-size: 552px;
}
.ico-min-mini{
	background: url(/images/sprite.png) -26px -16px no-repeat;
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 10px 15px 10px 10px;
	vertical-align: sub;
	background-size: 552px;
}



.ico-midtrans{
	background: url(/images/icon-midtrans.png) no-repeat;
	width: 115px;
	height: 28px;
	display: inline-block;
	background-size: 115px;
	vertical-align: top;
}

.ico-visabig{
	background: url(/images/sprite@2x.png) -256px 0 no-repeat;
	width: 100px;
	height: 64px;
	display: inline-block;
	// background-size: 640px 80px;
	vertical-align: top;
	margin: 0 5px 0 0;
}

.ico-mastercardbig{
	background: url(/images/sprite@2x.png) -360px 0 no-repeat;
	width: 100px;
	height: 64px;
	display: inline-block;
	// background-size: 640px 80px;
	vertical-align: top;
	margin: 0 5px 0 0;
}