input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
button[disabled], input[disabled] {
    cursor: default;
    background: #f6f6f6;
}
input, select, textarea {
	outline: none;
	font-family:'proximanova.regular';
	font-size:14px;
	letter-spacing: 0.3px;
	width:320px;
	height:40px;
	max-width: 100%;
	color:$grey6;	
	// border-radius: 6px;
	background:white;
	// border:1px solid #cdcdcd;
	box-shadow:none !important;
	border-radius: 0px !important;
	padding: 11px 12px;
	// margin-bottom:5px;
	// height:36px;		
	// color: $grey6;
	border:1px solid $grey2;
	box-sizing: border-box;
	// &:hover{
	// 	border:1px solid #d719d7;
	// 	-moz-box-shadow:    0 0 8px rgba(255,91,255,0.2);
	//    -webkit-box-shadow: 0 0 8px rgba(255,91,255,0.2);
	//    box-shadow:         0 0 8px rgba(255,91,255,0.2);
	//    outline:none;
	// }
	// &:focus,&:active{
	// 	border:1px solid #d719d7;
	// 	-moz-box-shadow:    0 0 8px rgba(255,91,255,0.2);
	//    -webkit-box-shadow: 0 0 8px rgba(255,91,255,0.2);
	//    box-shadow:         0 0 8px rgba(255,91,255,0.2);
	//    outline:none;
	// }
	&.small{
		float:left;
	    height: 34px;
		padding: 8px 12px;		
		font-size:14px;		
	}
	&.vsmall{
		float:left;
	    height: 30px;
		padding: 5px 12px;
		font-family:'proximanova.regular';	
	}
	&:focus{
		border:1px solid $grey6;
	}
	&.qty{
		float:left;
		width:60px;
		font-weight:bold;
		text-align: center;
	}
	&.qtyminus,
	&.qtyplus{
		float:left;
		width:50px;
	    font-size: 20px;
	    padding:0;
		font-weight:bold;
		border:1px solid $grey6;
	}
	&.error{
		border:1px solid red;
	}
}
input[type=file]{
	border-radius: 0;
	background:#140d13;
	border:1px solid #392429;
	box-shadow:none;
	padding: 4px;		
	color: #fff;
	font-family: 'Lato', sans-serif;
	font-size: 15px;
	// width: 300px;
	outline:none;
	&::-webkit-file-upload-button{
		font-family: 'Lato', sans-serif;
		color:#fff;
		height:35px;
		border:2px solid #6f1e8a;
		background:#140d13;
		padding:5px 8px;
	}
	&:before{
		display:none;
	}
}
textarea{
	width: 100%;
	// height: initial !important;
	height: 80px;
	padding: 12px 12px !important;
	resize: none;
}
input[type=radio], input[type=checkbox]{
	width:initial;
	box-sizing: border-box;
    padding: 0;
    height: 13px;
    vertical-align: middle;
    margin-right: 4px;
	&:before{
		border:none !important;
	}
	&.small{
		float: initial;
		height:10px;
	}
}		
select {
	@extend .bold;
	font-weight:bold;
	letter-spacing: 0;	
	cursor:pointer;
	display: inline-block;
	width:initial;
	option{
		font-size:12px;
		font-weight:normal;
	}
}

.input-disable{
	cursor: default;
    background: #f6f6f6;
}

.input__wrapper{	
	display: inline-block;
	float:left;
	width:300px;
	max-width:100%;
	margin:5px 10px 15px 0;
	.info{
		display: block;
		margin-top:5px;
		font-size:12px;
		color:$grey4;
		&__small{
			display: inline-table;
			margin:8px 0;
		} 
	}
	label{
		display: inline-block;
		margin-bottom:4px;
	}
}
.textarea__wrapper{
	float:left;
	width:100%;
	margin:5px 10px 8px 0;
}
.inputselect__wrapper{
	display: inline-block;
	float:left;
	width:100%;
	select{ min-width:72px; }
	// input{ margin-left:8px; }
}
.date__wrapper{
	position: relative;
	display: inline-block;
	.fa-calendar{
		position: absolute;
		top:5px; right:5px;
	    font-size: 23px;
		color:$grey3;
	}
}
.select__wrapper{

}
.label__wrapper{
	padding:8px 0px 8px 20px;
}

//alert
.info--success{
	display: block;
	margin:7px 5px;
	color:#39b54a !important;
}
.info--warning{
	display: block;
	margin:7px 5px;
	color:#d39a4a !important;
}
.info--error{
	display: block;
	margin:7px 5px;
	color:$red !important;
}
.info--red{
	display: initial;
	margin:0;
	color:$red !important;
}

.field-with-checkbox{
	input[type=checkbox]{
		margin-right: 0;
	}
	label{
		cursor: pointer;
	}
}

.form{
	&__wrapper{
		display: inline-block;	
		padding:10px 0 20px 0;
		.link{
			color:$grey4;
			font-family:'proximanova.regular';
		}
	}
	&--login{
		width:300px;
	}
	&--register{
		width:630px;
		.text-agree{
			display: inline-block;
			margin:10px 0 0 10px;
			font-size: 12px;
			a{ font-size: 12px; }
			small{
				font-size: 12px;
			}
		}
		.aggrement__wrapper{
			margin: 5px 10px 15px 0;
		}
		select.small{
			width: 72px;
		}
		.inputselect__wrapper{
			input{ width:calc(100% - 80px) !important; margin-left:8px; }
		}
	}
	&--forgotpass{
		.input__wrapper{
			width:500px;
		}
	}
	&--contactus{
		.input__wrapper{
			width:32.6%;
			margin:5px 1% 8px 0;
		}
	}
	&--homesubscribe{
		.input__wrapper{
			margin: 0 0 0 10px;
			.small{ width:245px; }
		}
	}
	&--footersubscribe{
		.input__wrapper{
			.small{ width:180px; }
		}
	}
	&--filter-products{
		.input__wrapper{
			width:inherit;
			margin:0;
			span{
				font-size: 13px;
				margin:8px 10px 0 0;
			}
			input, select{
				margin:0 10px 0 0;
			}
		}
	}
	&--productdetail{
		width:100%;
		.input__wrapper{
			width:100%;
			padding-left:15px;
			margin: 0 10px 8px 0;
		}
		.title__tag{
			letter-spacing: 1px;
			color:#000;
		}
	}
	&--shoppingcart{
		width:100%;
		.input__wrapper{
			width:100%;
			padding:0;
			margin: 5px 0 8px 0;
			.discount--input{
				width: 92px;
			}			    
		}
		.discount__value{
			.fa-times-circle{
				cursor: pointer;
				color:#cecece;
				margin-left: 5px;
				&:hover{
					color:#ff6060;
				}
			}
		}
	}
	&--onpayment{
		width:100%;
		.input__wrapper{
			width: 400px;
			clear: both;
			input{ width:100%; }
			input[type=radio], input[type=checkbox]{ width:initial; }
			label{
				cursor:pointer;
				color:$grey6;
			}
			span{
				font-size: 13px;						
			}
			select{ margin:0px 5px 5px 0px; }
			h5{ margin-bottom: 8px; }
			.select__wrapper{
				display: inline-block;
				width: 100%;
				.fa-calendar{
					font-size: 24px;
					color:$grey3;
					margin: 3px 3px 3px 10px;
				}					
			}
			.add-addrs{
				cursor: pointer;
				margin-top: -32px;
				padding:5px 0;
				text-decoration: underline;
				&:hover{ color:#000; }
			}
			.add-selectaddrs{
				display: none;
				margin-top:40px;
			}
			.column-label{
				display: inline-block;
				min-width: 130px;
			}
		}
	}
	&--account{
		width:400px;
		.input__wrapper{
			width:100%;
		}
	}
	&--popup-logreg{
		padding:20px 0;
		.input__wrapper{
			margin: 5px 0 5px 0;
		}
	}
	&--insert-voucher{
		.input__wrapper{
			position: relative;
			margin:0;
			&.error{
				input{ border:1px solid red; }
			}
			.discount--input{ width:125px }
		}
	}
	&--msearchbar{
		height:40px;
		padding: 10px;
		background:$grey6;
		.input__wrapper{
			position: relative;
			width:100%;		    	
			margin:0;
			padding:0;
			.input__msearchbar{  
				float:left;
				width:calc(100% - 74px);
				padding: 11px 11px 11px 35px;
			}
			.msearchbar__close{
				display: inline-block;
				padding: 11px 10px 11px 10px;
				border: 1px solid white;
				position: absolute;
				right: 5px;
			}
			.icon__msearch{
				position: absolute;
				top:2px; left:0px;
			}
			.icon__removecircle{
				position: absolute;
				top:2px; right:80px;
			}
		}
	}
}

@include break (1024){
	.form{
		&--filter-products{
			width:100%;
			.input__wrapper{
				width:100%;
				margin:0;
				span{ margin:8px 0 0 0; text-align: left !important; }
				.chosen-container{
					width:100% !important;
					margin: 5px 0 10px 0;
				}
				select{
					width: inherit;
					margin: 5px 0 10px 0;
					min-height: 34px;
				}
			}
		}
	}
}

@include break (980){
	.form{
		&--homesubscribe{
			width:100%;
			margin:10px 0 0 0;
			text-align: center;
			.input__wrapper{
				width: 50%;
				margin:0 auto;
				float:none;
				.small{ width: calc(100% - 48px); }
			}
		}
		&--footersubscribe{
			width:100%;
			text-align: center;
			.input__wrapper{
			    float: initial;
			    width:initial;
		        margin: 10px auto 45px auto;
				.small{ width:189px; }
			}
		}
		&--filter-products{
			.input__wrapper{
				.chosen-container{
					// width:98% !important;
				}
			}
		}
	}
}

@include break (768){
	.input__wrapper{
		width:100%;
		margin:0px 0 10px 0;
		.qty{ width:calc(100% - 100px);}
	}
	// .inputselect__wrapper{
	// 	.chosen-container{ width:100% !important; }
	// 	input{ width:100%; margin-left:0; }
	// }
	.form{
		&__wrapper{ padding:0 0; }
		&--login{
			width:100%;
			.input__wrapper{
				width:100%;
				.small{ width:100%; }
			}			
		}
		&--register{
			width:100%;
			.small{ width:100%; }
			.inputselect__wrapper{
				select{
					display: inline-block;
					margin-right: 0;
					padding: 3px 5px;
				}
			}
		}
		&--forgotpass{
			width:100%;
			.input__wrapper{
				width:100%;
				input{ width:calc(100% - 178px); margin-bottom:5px; }
			}
		}
		&--contactus{
			.input__wrapper{
				width:100%;
				margin:5px 0px 8px 0;
				input{ width:100%;}
			}
		}
		&--homesubscribe{
			.input__wrapper{
				.small{ width: calc(100% - 38px); }
			}
		}		
		&--footersubscribe{
			width:100%;
			text-align: center;
			.input__wrapper{				
			    float: initial;
		        margin: 10px auto 10px auto;
				.small{ width:189px; }
			}
		}
		&--onpayment{
			.input__wrapper{ width: 100%; }
		}
		&--account{
			width:100%;
		}
	}
}
@include break (480){
	.input__wrapper{ width:100%; }
	.form{
		width:100%;		
		&--footersubscribe{
			.input__wrapper{
				.small{ width:168px; }
			}
		}
	}
}
@include break (400){
	.form{
		&--insert-voucher{
			.input__wrapper{
				width:100%;
				.discount--input{ width:80px; }
			}
		}	
		&--homesubscribe{
			.input__wrapper{
				width:276px;
				margin: 0;
				.small{ width:85%; }
			}
		}
		&--onpayment{
			.input__wrapper{
				.add-addrs{
					display: block;
					float: none;
					padding-top: 10px;
					text-align: right;
				}
				.add-selectaddrs{
					.chosen-single{
						width: 100% !important;
					}
				}
			}
		}
	}
}
@include break (320){
	.form{
		&--forgotpass{
			.input__wrapper{
				.small{ width:100%; }
				.btn--main__small{ margin: 0 auto !important; }
			}
		}
		&--homesubscribe{
			.input__wrapper{
				width:276px;
				margin: 0;
				.small{ width:221px; }
			}
		}		
	}
}