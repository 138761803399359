*,
*:before,
*:after {
  	box-sizing: inherit;
}
a, a:active, a:focus, input, p, article {outline: none;}
iframe{border: none;}
body {
  background: white;
  margin:0;
  // overflow-x: hidden;
}
html,body{
	min-height: 100%;
    padding: 0;
}
img {
	display: block;
	max-width: 100%;
	height:auto;
	outline: none;
}
img[src=''], 
img:not([src]){ opacity:0; }
.noflicker{ 
	backface-visibility:hidden; 
	transform:translateZ(0) translate3d(0,0,0);
	transform-style: preserve-3d;
}
.hide{ display: none !important; }
.clearfix{ clear:both; }
.width-initial{width:initial !important;}
.left {
	text-align: left;
	justify-content: flex-start;
}
.right {
	text-align: right;
	justify-content: flex-end;
}
.center {
	text-align: center;
	justify-content: center;
}
.around {
	justify-content: space-around;
}
.between {
	justify-content: space-between;
}
.float-left{ display:inline; float:left; }
.float-right{ display:inline; float:right; }
.text-danger {
	color: #cc3f44 !important;
}
.text-warning {
	color: #ffba00 !important;
}
.text-success {
	color: #8dc63f !important;
}
.text-info {
	color: #40bbea !important;
}
.margin0{
	margin:0 !important;
}
.marginr0{
	margin-right:0 !important;
}
.spacing0{
	letter-spacing:0 !important;
}
.light{ font-family: 'proximanova.light'; }
.semibold{ font-family: 'proximanova.semibold'; }
.bold{ font-family: 'proximanova.bold'; }
.lora-regular{ font-family: 'lora.regular'; }
.lora-italic{ font-family: 'lora.italic'; }
.flex {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}
.flex-between {
	display: flex;
	justify-content: flex-between;
}
.widthfull{ width:100% !important; }
.container{
    // display: table;
    display: inline-block;
	position:relative;
	width:1024px;
	max-width:100%;
	margin:0 auto;	
	&__wrapper{
		display:block;
		margin: 0 auto;
		padding:22px;
	}
	&full {
		width: 100%;
		// max-width: 1200px;
		margin: auto;
		text-align: center;
	}
	&800{
		width: 800px;
		margin: auto;
	    max-width: 100%;
	}
	&600{
		width: 600px;
		margin: auto;
	    max-width: 100%;	    
	}
	&400{
		width: 400px;
		margin: auto;
	    max-width: 100%;
	}
}
.content{
	&__wrapper{
		min-height:460px;
		padding:0 0 40px 0;
	}
	&__read{		
		padding:0 0 40px 0;
	}
	&__box{
		.box__title{
			background:$grey6;
			color:white;
			letter-spacing: 0;
			padding:10px 20px;
		}
		.box__container{
			padding:20px 0;
		}
	}
}
.section{
	position: relative;
	padding:20px 0 20px 0;
	.banner{ margin-bottom:20px; }
	h2{}
}
.column{	
	&__wrapper{
		padding:20px;
		&--right{
			padding-right:40px;
		}
		&--left{
			padding-left:40px;
		}
	}
	&25{
		float:left;
		width:25%;		
	}
	&33{
		float:left;
		width:33.33%;		
	}
	&50{
		float:left;
		width:50%;
		&.border{
			border-right:1px solid $grey1;
			width:calc(50% - 1px);
		}
	}
}
.bg{
	&--white{
		background:white;
	}	
	&--mini1{
		background:url(/images/bg/bg-mini1.jpg) top center no-repeat;
		width:100%; height:100px;
		padding-bottom:20px;
	}
	&--mini2{
		background:url(/images/bg/bg-mini2.jpg) top center no-repeat;
		width:100%; height:100px;
		padding-bottom:20px;
	}
	&--aboutus{
		background:url(/images/bg/bg-aboutus.jpg) top center no-repeat;
		width:100%; height:600px;
		padding-top: 20px;
	}
}
.m-widthfull{}
.m-768{ display: none; }
.seo-text{
	border-top:1px solid #cccccc;
	p{ font-size:14px; }	
}
.img-center{
	margin: auto;
	text-align: center;
}

// Responsive
// height
@media (max-height: 700px) {
}

// width
@include break (1360){
}
@include break (1024){
	.container{ width:100%; }
	.bg{
		&--aboutus{
			background:none;
			height:initial;
			padding-top: 0px;
		}
	}
}
@include break (980){
	// .float-left{ display:block; float:initial; }
	// .float-right{ display:block; float:initial; }	
}
@include break (768){
	.flex {
		display: block;
	}
	.seo-text{ display: none; }
	.content{
		&__box{
			.box__container{ padding:0 0; }
		}
	}
	.section{
		padding:10px 0;
		.banner{ display: none; }
		h2 { text-align: center !important; letter-spacing: 2px; }
	}
	.m-widthfull{ width:100% !important; }
	.m-768{ display: inline-block !important; }
}
@include break (600){
	.section{ padding:5px 0 5px 0; }
}

