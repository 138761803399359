.payment{
	.column__wrapper--right{
		position: relative;
	}
	.over-or{
		position: absolute;
		top: 0;
		bottom: 0;
		right: -20px;
		margin: auto;
		padding: 20px 8px;
		background: white;
		height: 18px;
	}
	.content__read{
		padding: 0 0 200px 0;
	}
	&__logreg{
		&--left{
			float:left;
			border-right: 1px solid #e1e1e1;
    		width: calc(50% - 5px);
		}
		&--right{
			float:left;
			width:50%;
		}
	}
}
.payment__standard{
	width: 25%;
	display: inline-block;
	.chosen-container{
		.chosen-single{
			height: 34px;
			span{
				padding: 8px 0 7px 8px;
			}
			div{
				b{
					background-position: 0px 6px;
				}
			}
		}
		&.chosen-container-active{
			&.chosen-with-drop{
				.chosen-single{
					div{
						b{
							background-position: -18px 6px;
						}
					}
				}
			}
		}
	}
}
.btn__delete__coupon{
	width: 15px;
	height: 15px;
	border-radius: 100%;
	background-color: #DE6060;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	&:before{
		content:'\00D7';
	}
	&:hover{
		text-decoration: none;
		background-color: darken(#DE6060, 10%);
	}
}
.price__idr{
	display: inline-block;
}
.price{
	display: inline-block;
	width: 100px;
}

// .shipping_panel{
// 	.chosen-single{
// 		width: 200px !important;
// 	}
// }
// #payment-step3{ z-index: 1; }
// #payment-step2{ z-index: 2; }
// #payment-step1{ z-index: 3; }

// responsive
@include break (1360){
}
@include break (1024){
}
@include break (980){
	.with-step{
		padding:50px 0 0 0;
		.content__box{
			position: absolute;
			top:0;
			display: inline-block;
			width:100%;
			margin:0;			
			&.active{
				position: relative;
				display: block;				
				.box__title{ display: block; background:$grey6; }
				.step__circle{
					width:27px; height:22px;
					border:none;
					color:$grey6;
					background:white;
					border:1px solid $grey6;
				}
				&~ .box__container{ display: block; }
			}
			&.done{
				.box__title{ background:$grey6; }
				.step__circle{
					width:28px; height:23px;
					background:#333333;
					color:white;
				}
			}
			.step__circle{
				display: block;
				width:28px; height:23px;
				padding:5px 0 0 0;
				background:#d1d1d1;
				text-align: center;
				color:white;
				border:none;
				h5{ font-size: 13px; }
			}			
			.box__title{
				display: none;
				background:$grey1;
				position: relative;
			}		
		}	
		.box__container{
			display: none;
			padding:0;
			&__wrapper{
				padding:0;
				.box__content{
					padding:25px 0px;
				}
			}		
		}
	}
	#payment-step1{ 
		.step__circle{ top:-42px; left:0; right:initial; }
		.step__line-bottom{
		    top: -32px; left:0; right:initial;
			width: 50%; height:1px;
		}
		&.done{ 
			.step__circle{ top:8px; }
			.step__line-bottom{ top: 20px; } 
		}	
		&.active{ 
			.step__circle{ top:-42px; }
			.step__line-bottom{ top: -30px; } 
		}
	}
	#payment-step2{ 
		.step__circle{ top:5px; left:0; right:0; margin:0 auto; }
		.step__line-mid{
			top: 20px; left:0; right:initial;
			width: 100%; height:1px;
		}
		&.done{ 
			.step__circle{ top:5px; } 
			.step__line-mid{ top:20px; }
		}	
		&.active{ 
			.step__circle{ top:-42px; } 
			.step__line-mid{ top:-30px; }
		}
	}
	#payment-step3{ 
		.step__circle{ top:5px; left:initial; right:0; }			
		.step__line-top{
			top: 20px; left:initial; right:0;
			width: 50%; height:1px;
		}
		&.done{ 
			.step__circle{ top:5px; }
			.step__line-top{ top: 20px; } 
		}
		&.active{ 
			.step__circle{ top:-44px; }
			.step__line-top{ top: -30px; } 
		}
	}
}
@include break (768){
	.payment{
		&__logreg{
			&--left{
				float:initial;
				border-right: 0;
	    		width: 100%;
			}
			&--right{
				float:initial;
				width:100%;
			}
		}
		.column__wrapper--left,
		.column__wrapper--right{
			padding:0;
		}
		.over-or{ display: none; }
	}
	.payment__standard{
		width: 30%;
	}
}
@include break (600){
	.payment__standard{
		width: 100%;
		&.label__wrapper {
			padding: 4px 0px 4px 5px;
		}
	}
}
@include break (480){
}
@include break (320){
}


