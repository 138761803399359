.expand-collapse{	
	.content__box{
		padding-bottom:10px;
	}
	&__link{
		cursor:pointer;
		&:hover{
			background:#222;
		}
		.ico-expand-collapse{
			display: inline-block;
			position: relative;
			width:13px; height:13px;
			margin-right:15px;
			&:before{
				content:"";
				position: absolute;
				top:0; right:0; bottom:0; left:0;
				margin:auto;
				width:3px; height:100%;
				background:white;
				transition:0.4s ease;				
			}
			&:after{
				content:"";
				position: absolute;
				top:0; right:0; bottom:0; left:0;
				margin:auto;
				width:100%; height:3px;
				background:white;
				transition:0.4s ease;				
				opacity:1;
			}				
		}
		&.active{
			.ico-expand-collapse{
				&:before{
					transform: rotate(90deg);
				}
				&:after{
					transform: rotate(180deg);
					opacity:0;
				}
			}	
		}
	}
	.box__container{
		display: none;
		&.active{
			
		}
		&__wrapper{
			display: inline-block;
			padding: 20px 0;
		}
	}
}

.with-step{
	position: relative;
	.content__box{
		position: relative;
		margin-left:60px;		
		// &#payment-step3{
		//     width: 462px;
		//     // z-index: 210;
		// }
		&.active{
			.box__title{
				background:$grey6;
			}
			.step__circle{
				width:32px; height:32px;
				border:2px solid $grey6;
				color:$grey6;
			}
		}
		&.done{
			.box__title{
				background:$grey6;
			}
			.step__circle{
				width:32px; height:32px;
				background:$grey6;
				color:$grey1;
				border:none;
			}
		}
		.step__circle{
			position:absolute;
			top:5px; left:-60px;
			width:30px; height:30px;
			@extend .flex;
			justify-content: center;
			color:$grey1;
			background:white;
			border:2px solid $grey1;
			border-radius:50%;
			z-index: 1;
		}
		.step__line-top{
			position: absolute;
			top:0; left:-44px;
			width:1px; height:20px;
			background:$grey1;
			z-index: 0;

		}
		.step__line-mid{
			@extend .step__line-top;
			height:100%;
		}
		.step__line-bottom{
			@extend .step__line-top;
			top:10px;
			height:100%;
		}
		.box__title{
			background:$grey1;
			position: relative;
			// z-index: 210;
			.when-not{
			
			}
			.when-on{
				position: absolute;
				top:12px; left:0; right:0;
				text-align: center;	
				font-family:'proximanova.regular', arial, sans-serif;
				font-size:14px;		
				font-weight:normal;
			}
		}		
	}	
	.box__container{
		padding:0;
		&__wrapper{
			padding:0;
			.box__content{
				position: relative;
				// display: inline-table;
				display: block;
				// z-index: 210;
				padding:25px 15px;
			    background: white;
			    &50{
			    	width:492px;
			    }

			}
		}		
	}
}

// radio expendcollapse
.radio__expand-collapse {
    display: none;
    &__wrapper{
        position:relative;
        label, input[type=radio]{
	    	cursor:pointer;
	    }
    }
    &__content{
        // padding:0 1em;
    }    
}

@include break (1024){
}

@include break (768){
	.with-step{
		.box__container__wrapper{ 
			.box__content{ 
				h4{ letter-spacing: 0; }
			}
		}
	}
}