// custom default
#body{
  .bx-wrapper {
    box-shadow: none;
    border: none;
    background: none;
    margin: 0 auto 5px auto;
    .bx-viewsport{

    }
    .bx-controls{
      .bx-controls-direction{
        a{
          top: 50%;
          margin-top: -16px;
          outline: 0;
          width: 40px;
          height: 60px;
          opacity:0.5;
          z-index: 51;
          &:hover{ opacity:1;}
        }
        // .bx-prev{
        //   background: url("/images/arrow.png") no-repeat 0 -30px;
        // }
        // .bx-next{
        //   background: url("/images/arrow.png") no-repeat 0 0;
        // }
      }
      .bx-pager{
        bottom: 15px !important;
        &-item{

        }
        &-link{
          width: 8px;
          height: 8px;
          margin: 0 5px;
          border-radius: 50%;
          background:white;
          position: relative;
          z-index: 51;
          &.active{
            background:$grey6;
          }
        }
      }
    }
  }
  .bxslider{
    li{
      
    }
  }
  /* LOADER */
  .bx-wrapper .bx-loading {
    background: url('/images/bx_loader.gif') center center no-repeat #ffffff;
  }
  /* DIRECTION CONTROLS (NEXT / PREV) */
  .bx-wrapper .bx-prev {
    background: url("/images/arrows.png") no-repeat 0 0;
  }
  .bx-wrapper .bx-next {
    background: url("/images/arrows.png") no-repeat -40px 0;
  }
  /* AUTO CONTROLS (START / STOP) */
  .bx-wrapper .bx-controls-auto .bx-start {
    background: url('/images/controls.png') -86px -11px no-repeat;
  }
  .bx-wrapper .bx-controls-auto .bx-stop {
    background: url('/images/controls.png') -86px -44px no-repeat;
  }
}

// home banner
.bxslider--homebanner,
.bxslider--flowercourse,
.bxslider--weddingworld{
  visibility: hidden;
  .bx-wrapper{
    margin:0 auto !important;
    .bx-viewport{
      .bxslider{
        li{
          position: relative !important;
          transition:0.4s ease !important;
          .product-detail__img{
          }
        }
      }
    }
    .bx-controls{
      .bx-controls-direction{
        a{          
        }
      }
      .bx-pager{
        bottom: 15px !important;
        // opacity:0.6 !important;
      }
    }
  }
}

// product detail
.bxslider--productdetail{
  // visibility: hidden;
  .bx-wrapper{
    .bx-viewport{
      .bxslider{
        li{
          position: relative !important;
          transition:0.4s ease !important;
          .product-detail__img{
          }
        }
      }
    }
    .bx-controls{
      .bx-controls-direction{

      }
    }
  }
  #bx-pager{
    a{ border:3px solid #eee; }
    .active{ border:3px solid #39b54a; }
  }
}

// for all anotherproduct (home & product detail)
.bxslider--anotherproduct{
  visibility: hidden;
  .bx-wrapper{
    .bx-viewport{
      height:400px !important;
      .bxslider{
        .product{
          position: relative !important;
          transition:0.4s ease !important;
          margin:0 25px 0 0;
          width:310px !important;
          .product__img{}
          .product__desc{
            .product__title{}
            p{}
          } 
          .product-detail__img{}
        }
      }
    }
    .bx-controls{
      .bx-controls-direction{
        .bx-prev{
          top:40% !important;
          left:-50px;
        }
        .bx-next{
          top:40% !important;
          right:-50px;
        }
      }
    }
  }
}

// responsive
@include break (1024){
  .bxslider--anotherproduct{
    .bx-wrapper{
      .bx-viewport{
        height:290px !important;
        .bxslider{
          .product{
            margin:0 15px 0 0;
            width:220px !important;
            .product__desc{
              .product__title{
                font-size: 15px;
                letter-spacing: 0;
              }
            }
          }
        }
      }
      .bx-controls{
        .bx-controls-direction{
          a{ opacity: 1 !important; }
          .bx-prev{
            top:32% !important;
            left:-20px;
          }
          .bx-next{
            top:32% !important;
            right:-20px;
          }
        }
      }
    }
  }
}

@include break (768){
  .bxslider--anotherproduct{
    .bx-wrapper{
      .bx-viewport{
        height:220px !important;
        .bxslider{
          .product{ width:150px !important; }
        }
      }
      .bx-controls{
        .bx-controls-direction{
          .bx-prev{ top:30% !important; }
          .bx-next{ top:30% !important; }
        }
      }
    }
  }
  .bxslider--productdetail{
    .bx-wrapper{
      .bx-viewport{
        .bxslider{
          li{
            img{ margin:0 auto; }
          }
        }
      }
      .bx-controls{
        .bx-controls-direction{

        }
      }
    }
    #bx-pager{
      text-align: center;
      a{ width:50px; height:50px; }
    }
  }
}

@include break (600){
  .bxslider--anotherproduct{
    .bx-wrapper{
      .bx-viewport{
          height: 160px !important;
        .bxslider{
          .product{
            width:110px !important;
            margin:0 10px 0 0;
          }
        }
      }
      .bx-controls{
        .bx-controls-direction{
          .bx-prev{ top:28% !important; }
          .bx-next{ top:28% !important; }
        }
      }
    }
  }
}