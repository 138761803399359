.breadcrumb {
	display: block;
	list-style: none;
    padding: 10px 34px;
    margin:0;
	li {
		display: inline-block;	
		padding: 5px 0 5px 5px;
		&:first-child{
			padding: 5px 0 5px 0;
		}
		&:last-child{
			a{
				color:#9fa197;
				&:after{ display:none; }
			}			
		}
	}
	a {
		display: inline-block;
		&:after {
			@extend .semibold;
			content: '/';
			display: inline-block;
			padding-left: 10px;
		}
		small{ font-size:13px; }		
	}
}

@include break (768){
	.breadcrumb { display: none; }
}