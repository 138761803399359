/* Gotham */
@font-face {
  font-family: 'gotham.medium';
  src: url('/fonts/gotham/Gotham-Medium.eot');
  src: url('/fonts/gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gotham/Gotham-Medium.woff2') format('woff2'),
    url('/fonts/gotham/Gotham-Medium.woff') format('woff'),
    url('/fonts/gotham/Gotham-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'gotham.medium-italic';
  src: url('/fonts/gotham/Gotham-MediumItalic.eot');
  src: url('/fonts/gotham/Gotham-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gotham/Gotham-MediumItalic.woff2') format('woff2'),
    url('/fonts/gotham/Gotham-MediumItalic.woff') format('woff'),
    url('/fonts/gotham/Gotham-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'gotham.black';
  src: url('/fonts/gotham/Gotham-Black.eot');
  src: url('/fonts/gotham/Gotham-Black.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gotham/Gotham-Black.woff2') format('woff2'),
    url('/fonts/gotham/Gotham-Black.woff') format('woff'),
    url('/fonts/gotham/Gotham-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'gotham.black-italic';
  src: url('/fonts/gotham/Gotham-BlackItalic.eot');
  src: url('/fonts/gotham/Gotham-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gotham/Gotham-BlackItalic.woff2') format('woff2'),
    url('/fonts/gotham/Gotham-BlackItalic.woff') format('woff'),
    url('/fonts/gotham/Gotham-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'gotham.bold';
  src: url('/fonts/gotham/Gotham-Bold.eot');
  src: url('/fonts/gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gotham/Gotham-Bold.woff2') format('woff2'),
    url('/fonts/gotham/Gotham-Bold.woff') format('woff'),
    url('/fonts/gotham/Gotham-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'gotham.bold-italic';
  src: url('/fonts/gotham/Gotham-BoldItalic.eot');
  src: url('/fonts/gotham/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gotham/Gotham-BoldItalic.woff2') format('woff2'),
    url('/fonts/gotham/Gotham-BoldItalic.woff') format('woff'),
    url('/fonts/gotham/Gotham-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'gotham.light';
  src: url('/fonts/gotham/Gotham-Light.eot');
  src: url('/fonts/gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gotham/Gotham-Light.woff2') format('woff2'),
    url('/fonts/gotham/Gotham-Light.woff') format('woff'),
    url('/fonts/gotham/Gotham-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'gotham.light-italic';
  src: url('/fonts/gotham/Gotham-LightItalic.eot');
  src: url('/fonts/gotham/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gotham/Gotham-LightItalic.woff2') format('woff2'),
    url('/fonts/gotham/Gotham-LightItalic.woff') format('woff'),
    url('/fonts/gotham/Gotham-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'gotham.book';
  src: url('/fonts/gotham/Gotham-Book.eot');
  src: url('/fonts/gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gotham/Gotham-Book.woff2') format('woff2'),
    url('/fonts/gotham/Gotham-Book.woff') format('woff'),
    url('/fonts/gotham/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'gotham.book-italic';
  src: url('/fonts/gotham/Gotham-BookItalic.eot');
  src: url('/fonts/gotham/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gotham/Gotham-BookItalic.woff2') format('woff2'),
    url('/fonts/gotham/Gotham-BookItalic.woff') format('woff'),
    url('/fonts/gotham/Gotham-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


/* Lora */
@font-face {
  font-family: 'lora.regular';
  src: url('/fonts/lora/Lora-Regular.eot');
  src: url('/fonts/lora/Lora-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/lora/Lora-Regular.woff') format('woff'),
    url('/fonts/lora/Lora-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'lora.italic';
  src: url('/fonts/lora/Lora-Italic.eot');
  src: url('/fonts/lora/Lora-Italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/lora/Lora-Italic.woff') format('woff'),
    url('/fonts/lora/Lora-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'lora.bold';
  src: url('/fonts/lora/Lora-Bold.eot');
  src: url('/fonts/lora/Lora-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/lora/Lora-Bold.woff') format('woff'),
    url('/fonts/lora/Lora-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'lora.bold-italic';
  src: url('/fonts/lora/Lora-BoldItalic.eot');
  src: url('/fonts/lora/Lora-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/lora/Lora-BoldItalic.woff') format('woff'),
    url('/fonts/lora/Lora-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}


/* Proxima Nova */
@font-face {
  font-family:'proximanova.bold';
  src: url('/fonts/proximanova/proximanova.bold.eot');
  src: url('/fonts/proximanova/proximanova.bold.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.bold.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.bold.woff') format('woff'),
       url('/fonts/proximanova/proximanova.bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proximanova.light';
  src: url('/fonts/proximanova/proximanova.light.eot');
  src: url('/fonts/proximanova/proximanova.light.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.light.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.light.woff') format('woff'),
       url('/fonts/proximanova/proximanova.light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proximanova.regular';
  src: url('/fonts/proximanova/proximanova.regular.eot');
  src: url('/fonts/proximanova/proximanova.regular.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.regular.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.regular.woff') format('woff'),
       url('/fonts/proximanova/proximanova.regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proximanova.semibold';
  src: url('/fonts/proximanova/proximanova.semibold.eot');
  src: url('/fonts/proximanova/proximanova.semibold.eot?#iefix') format('embedded-opentype'),
       url('/fonts/proximanova/proximanova.semibold.woff2') format('woff2'),
       url('/fonts/proximanova/proximanova.semibold.woff') format('woff'),
       url('/fonts/proximanova/proximanova.semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* fontawesome */
@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?v=4.5.0');
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.5.0') format('embedded-opentype'), 
	   url('/fonts/fontawesome/fontawesome-webfont.woff2?v=4.5.0') format('woff2'), 
	   url('/fonts/fontawesome/fontawesome-webfont.woff?v=4.5.0') format('woff'), 
	   url('/fonts/fontawesome/fontawesome-webfont.ttf?v=4.5.0') format('truetype'), 
	   url('/fonts/fontawesome/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}



