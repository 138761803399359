.pagination {
	display: flex;
	justify-content: center;
	padding: 0;
	li {
		float:left;
		display: inline-block;
		margin: 0 4px;
		&.active {
			a{
				@extend .bold;
				color:#222;
				text-decoration: underline;
			}			
			// &:hover{ a{color:#222;}}
		}
	}
	&__a {
		display: block;
		min-width: 15px;
		letter-spacing: 0;
		&:hover { @extend .bold; color:#222; }
		&.disabled{ opacity: 0.2; font-weight: normal; cursor: default;}
	}
	&__prev {
		display: block;
		min-width: 15px;
		letter-spacing: 0;
		&:hover { @extend .bold; color:#222; }
		&.disabled{ opacity: 0.2; font-weight: normal; cursor: default;}
	}
	&__next {
		display: block;
		min-width: 15px;
		letter-spacing: 0;
		&:hover { @extend .bold; color:#222; }
		&.disabled{ opacity: 0.2; font-weight: normal; cursor: default;}
	}			
}

@include break (1024){
	.pagination {
		display: inline-block;
		margin: 0 auto;
		li {
			margin: 0 7px 0 0;
			&:first-child,
			&:last-child{
				.pagination__a{ color:white; background: #aaa; border:1px solid #aaa;}
			}
			&.active {
				a{
					color:white;
					text-decoration: none;
					background:#222;
				}			
				// &:hover{ a{color:#222;}}
			}
		}
		&__a {
			font-size: 18px;
			padding:5px 10px;
			border: 1px solid $grey6;
			&:hover{
			    font-family: 'proximanova.regular';
			}
			&.disabled{ display: none; font-weight: normal; cursor: default;}
		}
		&__next {
			font-size: 18px;
			padding:5px 10px;
			border: 1px solid $grey6;
			&:hover{
			    font-family: 'proximanova.regular';
			}
			&.disabled{ display: none; font-weight: normal; cursor: default;}
		}
		&__prev {
			font-size: 18px;
			padding:5px 10px;
			border: 1px solid $grey6;
			&:hover{
			    font-family: 'proximanova.regular';
			}
			&.disabled{ display: none; font-weight: normal; cursor: default;}
		}	
	}
}