.shoppingcart{
	&__img{
		width:80px;
		height:80px;
		margin:0 30px 30px 0;
		background-color:#d7d7d7;
	}
	&__detail{
		width:calc(100% - 110px);
		.chosen-single{
			width: 230px;
		}
		ul {
			li{
				display: inline-block;
				width:100%;
			}
		}
		.product-detail__pack{
			margin:0 5px 14px 0;
			.pack__title{
				width:78px;
				b{
					display: block;
				}
			}
		}
		select.small{
			height: 34px !important;
			padding: 3px 5px;
		}
	}
	&__title{
		display: inline-block;
		margin-right:10px;
		margin-top:7px;
	}
	&__type{
		color:$grey5;
	}
	&__tag{
		color:$grey5;
	}
	&__price{
		margin:10px 0 40px 0;
	}
	&__detailitem{}
	&__detailitem-edit{ display: none; }
	&__giftnote{ padding: 0 0 0 26px; }
}
.order-summary{
	.shoppingcart__title{
		margin-top: 0;
	}
}

// responsive
@include break (1360){
}
@include break (1024){
}
@include break (980){
}
@include break (768){
	.shoppingcart{
		&__img{
			float:left;
			// width:50px; height:50px;
			margin:0 10px 10px 0;
		}
		&__detail{
			width:100%;
			float:left;
			.product-detail__pack{
				margin:0 5px 14px 0;
				.pack__title{
					width:78px;
				}
			}
		}
		&__proccess--top{ display: none; }

		ul.disc{
			padding: 8px 0 8px 0px;
		}
	}
}
@include break (480){
	.shoppingcart__detailitem-edit{
		textarea{
			height: 53px !important;
			padding: 5px !important;
		}
	}
	.shoppingcart{
		&__detail{
			.product-detail__pack{
				width: 100%;
				margin: 5px 0;
				.pack__title{
					width: calc(100% - 17px);
					font-size: 13px;
					b{
						display: inline-block;
						margin-right: 3px;
					}
				}
				.pack__img{
					display: none;
				}
			}
		}
	}
}
@include break (400){
	.shoppingcart{
		&__detail{
			ul.disc{
				padding: 0px;
			}
			// .product-detail__pack {
			// 	width: 40%;
			// }
		}
		&__proccess--bottom{
			margin: 5px;
		}
	}
	.products__allflowers{
		.btn--box__small, .btn--main__small{
			margin: 5px;
		}
	}
}


