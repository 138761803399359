// base custom
.swiper-container{   
    margin-bottom:40px; 
}
.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    margin-top:-80px;
    width: 40px;
    height: 60px;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    background: url("/images/arrows.png") no-repeat 0 0;
    left: -50px;
    right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    background: url("/images/arrows.png") no-repeat -40px 0;
    right: -50px;
    left: auto;
}

.swiper--homebanner{
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        background: url("/images/arrows.png") no-repeat 0 0;
        left: 10px;
        right: auto;
        margin-top: -20px;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        background: url("/images/arrows.png") no-repeat -40px 0;
        right: 10px;
        left: auto;
        margin-top: -20px;
    }
}

.swiper-pagination-bullet-active{
    background: #fff;
}
.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active{
    background-color: white;
}

@include break (1024){
    .swiper-button-prev,
    .swiper-button-next {
        margin-top:-60px;
    } 
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 0px;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 0px;
    }  
}

@include break (768){
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: -20px;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: -20px;
    }  
}