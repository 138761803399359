.tab-link{
	&__a{
		cursor:pointer;
	}
}
.tab-container{
	display: none;
	&:nth-child(1){
		display: block;
	}
}