.account{
	.tab-link{
		&__wrapper{
			display: inline-block;
			width:205px;
			overflow: hidden;
		}
		&__label{
			display: inline-block;
			background:$grey6;
			width:100%;
			padding:10px 0 10px 10px;
			color:white;
		}
		&__ul{
			width:100%;
			padding:0;
		}
		&__li{
			width:100%;			
			&.active{
				background:#ebebeb;
				&:hover{ background:#ebebeb; }
			}
			&:hover{
				background:#f9f9f9;
			}
		}
		&__a{
			display: block;
			padding:10px;
		}
	}
	.tab-container{
		&__wrapper{
			display: inline;
			width:712px;
		}
		&#orderhistory{
			min-height: 400px;
		}
	}
}

.invoice{
	display: none;
}
.invoice__link{
	cursor: pointer;
}

.shipaddrs-edit, .shipaddrs-add{
	display: none;
}
.payment__confirmation__expired{
	min-height: 500px;
}

#personal_details, #change_password{
	.info--error{
		display: block;
	}
}

.form--reset-pass{
	.input__wrapper{
		.info--error{
			display: none;
		}
	}
}
.btn-form-shipping-address-cancel{
	margin: 0 10px 0 0 !important;
}
// responsive
@include break (1360){
}
@include break (1024){
	.account{
		.tab-link{
			&__wrapper{	width:100%; margin-bottom:30px; }
			&__li{
				display: inline-block;
				float:left;
				width:25%;
				background:#ebebeb;	
				text-align: center;		
				&.active{
					background:#fff;
					&:hover{ background:#fff; }
				}
			}
			&__a{ padding:15px 10px; }
		}
		.tab-container{
			&__wrapper{ width:100%; }
		}
	}
}
@include break (768){
	.account{
		.tab-link{
			&__li{
				width:100%;
				border-bottom:1px solid #ddd;
			}
			&__a{ padding:10px 10px; }
			&__label{
				text-align: center;
			}
		}
	}
	.btn-form-shipping-address-cancel{
		margin: 10px 0 0 0 !important;
	}
}
@include break (480){
}
@include break (320){
}


