table {
	width:100%;
	max-width: 100%;
	margin:25px 0;
	thead{
		tr{
			border-top:1px solid $grey3;
			border-bottom:1px solid $grey3;
			th{
				font-size: 15px;
				font-family:'proximanova.bold';
				padding:15px 0;
			}
		}
	}
	tbody{
		tr{
			border-bottom:1px solid #e1e1e1;
			&.tr--linegrey{
				border-bottom:1px solid #e1e1e1;
			}
			&.tr--bggrey{
				background:#ebebeb;
			}
			&.none{
				border:none;
				td{
					padding:5px 0;
				}
			}
			td{
				letter-spacing: 0.3px;
				font-size: 15px;
				padding:25px 0;
				a{
					text-decoration: none;
					cursor:pointer;
					&:hover{ text-decoration: underline; }
				}
			}
		}
	}	
}

.order-detail{
	thead{
		tr{
			th{
				&:nth-child(1){ width:45px; }
				&:nth-child(2){ width:calc(60% - 45px); }
				&:nth-child(3){ width:17%; }
				&:nth-child(4){ width:6%; }
				&:nth-child(5){ width:17%; }
			}
		}
	}
	tbody{
		tr{
			&.total-order{}
			&.m-total-order{display: none;}
			td{
				.label__wrapper{ padding:0 40px; }
			}
		}
	}
}

.order-history{
	thead{
		tr{
			background:#cfd0cb;
			th{
				padding:10px 0;
				&:nth-child(1){ width:45px; }
				&:nth-child(2){ width:calc(40% - 45px); }
				&:nth-child(3){ width:20%; }
				&:nth-child(4){ width:20%; }
				&:nth-child(5){ width:30%; }
			}
		}
	}
	tbody{
		tr{
			&:nth-child(even){
				background:#f2f2f2;
			}
			td{
				padding:5px 0;				
			}
		}
	}
}

.order-invoice{
	margin:10px 0;
	thead{
		tr{
			th{ &:nth-child(1){ width:150px; } }
		}
	}
	tbody{
		tr{
			td{ &:nth-child(1){ width:150px; } }
		}
	}
}

.order-information{
	margin:10px 0;
	thead{
		tr{
			th{
				&:nth-child(1){ width:45px; }
				&:nth-child(2){ width:calc(70% - 45px); }
				&:nth-child(3){ width:10%; }
				&:nth-child(4){ width:20%; }
			}
		}
	}
	tbody{
		tr{
			td{
				.label__wrapper{
					padding:0 10px;
				    white-space: nowrap;
				}
			}
		}
	}
}

.order-status{
	margin:10px 0;
	tbody{
		tr{
			td{
				&:nth-child(1){ width:150px; }
			}
		}
	}
}

.order-summary{
	margin:15px 0 70px 0;
	thead{
		tr{
			th{
				font-size: 13px;
				padding:10px 0;
				text-align: center;
				&:nth-child(1){ width:40px; }
				&:nth-child(2){ width:calc(63% - 40px); }
				&:nth-child(3){ width:12%; }
				&:nth-child(4){ width:25%; }
			}
		}
	}
	tbody{
		tr{	
			border-bottom:1px solid $grey3;
			&.tr--shipping{}
			&.tr--vouchercode{}
			&.tr--adjustmentcode{}
			&.tr--total{}				
			td{
				font-size: 13px;
				padding:10px 0;
				letter-spacing: 0;
			    line-height: 20px;
				.shoppingcart__detail {
				    width: inherit;
				}
				.shoppingcart__title{
					font-size: 15px;
				}
				.shoppingcart__price{
					font-size: 13px;
					white-space: nowrap;
					line-height: normal;
					margin: 0 0 15px 0;
				}
				.label__wrapper{
					padding:0 15px;
				}
				.column-label{
					display: inline-block;
					width:120px;
				}
				.column-content{

				}
				.discount__value{}
				.m-discount__value{ display: none; }
			}
		}
	}	
}

.delivery-summary{
	margin:15px 0 70px 0;
	thead{
		tr{
			border-top:1px solid #d8d8d8;
			border-bottom:none;
			th{
			    font-family: 'proximanova.regular';
				font-size:12px;
				font-weight:normal;
				color:$grey4;
				width:20%;
				padding:20px 10px;
			}
		}
	}
	tbody{
		tr{
			border:none;				
			td{
				font-size: 13px;
				padding:0px 10px;
				letter-spacing: 0;
			    line-height: 20px;
			}
		}
	}	
}
.calculate{
	width:initial;
	tr{
		border-bottom:0;
		&.line{
			border-bottom: 1px solid $grey6;
		}
		td{
			padding:5px 0;
		}
	}
}

@include break(768) {
	table{
		tbody tr td{ padding:10px 0; font-size:13px; }
	}
	.order-detail{
		thead{
			tr{
				th{
					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5){ display: none; }
				}
			}
		}
		tbody{
			tr{
				position:relative;
				&.total-order{ display: none; }
				&.m-total-order{ display: table-row; }
				td{
					// &:nth-child(5),
					// &:nth-child(4),
					&:nth-child(3){ display: none; }
					.label__wrapper{
						padding:0 15px;
					}
					&:nth-child(4){
						position: absolute;
						right: 0;
						width: 50px;
					}
					&:nth-child(5){
						right: 0;
						text-align: right;
						top: 39%;
						position: absolute;
						display: block;
					}
				}
			}
		}
	}

	.order-history{
		thead{
			tr{
				th{
					font-size: 13px;
					&:nth-child(1){ display: none; }
					&:nth-child(2){ width:20%; }
				}				
			}
		}
		tbody{
			tr{
				td{
					font-size: 13px;
					.invoice__link{ 
						// background:$grey6; color:white; 
						padding:5px 7px; }
					&:nth-child(1){ display: none; }
					&:nth-child(2){ width:10%; }
				}
			}
		}
	}	

	.order-information{
		margin:10px 0;
		thead{
			tr{
				th{
					&:nth-child(1){ display: none; }
					&:nth-child(2){ width:100%; }
					&:nth-child(3){ display: none; }
					&:nth-child(4){ display: none; }
				}
			}
		}
		tbody{
			tr{
				&.tr--shipping,
				&.tr--total{ display: none; }
				td{
					.label__wrapper{ padding:0 5px; font-size:13px; }
					&:nth-child(1){ display: none; }
					&:nth-child(2){ width:100%; }
					&:nth-child(3){ display: none; }
					&:nth-child(4){ display: none; }
				}
			}
		}
	}

	.order-status{
		tbody{
			tr{
				td{ &:nth-child(1){ width:100px; } }
			}
		}
	}

	.order-summary{
		margin:15px 0 70px 0;
		thead{
			tr{
				th{
					&:nth-child(3),
					&:nth-child(4){ display: none; }
				}
			}
		}
		tbody{
			tr{
				&.tr--shipping,
				&.tr--vouchercode,
				&.tr--adjustmentcode,
				&.tr--total{ td{
					&:nth-child(2){ display: none; }
				} }		
				td{
					&:nth-child(3),
					&:nth-child(4){ display: none; }
					.shoppingcart__detail{ width:100%; }
					.m-discount__value{ display: block; }
				}
			}
		}	
	}

	.delivery-summary{
		display: none;
	}
}


@include break(480) {
	.order-detail{
		tbody{
			tr{
				td{
					&:nth-child(5){
						display: none;
					}
				}
			}
		}
	}
}

@include break(400) {
	.order-history{
		thead{
			tr{
				th{ &:nth-child(2){ display: none; } }				
			}
		}
		tbody{
			tr{
				td{ &:nth-child(2){ display: none; } }
			}
		}
	}
	.order-invoice{
		thead{
			tr{
				th{ &:nth-child(1){ width:80px; padding-right:5px;} }
			}
		}
		tbody{
			tr{
				td{ &:nth-child(1){ width:80px; padding-right:5px;} }
			}
		}
	}
}