.rev-background-color-home{
  background-color: #fbf4ee;
}
.home__subscribe{
	display: flex;
	justify-content: center;
	align-items:center;
	.input__wrapper{
		margin-left:25px;
		.input--small{ width:240px;}
	}	
}

.home__type{
	display: flex;
	justify-content:space-between;	
	.col{
		&--left{
			display: inline-block;
			position: relative;
			width:645px;
			&:hover{
				.col__box{ 
					width:100%; height:100%; 
					.btn--box__small{transition:0.4s ease; bottom:90px;}
				}
				.home__type__desc{
					transition:0.4s ease; opacity: 1; visibility: visible; height:inherit;
				}
			}
			.event__img{
				img.ori__img{
					max-height: 270px;
					margin: auto;
					// height: 100%;

					// position: absolute;
		   //          width:100% !important;
		   //          height:100% !important;
		   //          left: -100%;
		   //          right: -100%;
		   //          top: -100%;
		   //          bottom: -100%;
		   //          margin: auto;
		}
	}
}
.home__banner{
	.swiper-container{
		margin: 0;
	}
}
&--right{
	@extend .col--left;
	width:310px;
}
&__box{
	position: absolute;
	top:0; right:0; left:0; bottom:0;
	width:250px; height:250px;
	margin:auto;			
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	&__wrapper{
		padding:0px 20px 25px 20px;
	}
	.btn--box__small{
		transition:0s ease;				
		position:absolute;
		bottom:25px; right:0; left:0; 
		width:60px;
		margin: 0 auto;
		padding:10px 0;
		font-family: 'proximanova.bold';
		font-size:11px;
	}
}
&__title{
	font-family: 'lora.italic';
	text-align: center;
}		
}
&__desc{
	transition:0s ease;
	opacity: 0; visibility: hidden;
	letter-spacing: 1.5px;
	display: block;
	position: relative;
	// height:0;
}
}

.home__event{
	position: relative;	
	&:after{
		visibility: hidden;
		opacity: 0;
		content: "";
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background:rgba(255, 255, 255, 0.6);
		transition:0.4s all;
	}
	&:hover{
		&:after{
			visibility: visible;
			opacity: 1;
		}
	}
	.event{
		&__banner{
			position: relative;
		}
		&__content{
			position: absolute;
			top:0; bottom:0;
			z-index: 1;
			margin:auto;
			width:440px;
			height: 80%;			
			display: flex;
			flex-flow:row wrap;
			justify-content:center;
			align-items:center;
			&__wrapper{
				padding:0 50px;
			}
			&--right{
				right:0;
			}
			&--left{
				left:0;
			}
		}
		&__title{
			font-size: 48px;
			width: 100%;
		}
		&__desc{
			font-size: 26px;
			width: 100%;
		}
		&__hr{
			display: block;
			width: 85px;
			border:none;
			border-top:2px solid $grey6;
			margin: 15px auto 24px auto;
		}
		&__img{

		}
	}
}

.home__editorpick{padding-bottom: 60px;}

.ig__section{
	border-top: 1px solid rgba(233,233,233,1);
	padding: 50px 0;
	.title{
		font-size: 30px;
		font-family: 'lora.italic';
		margin-bottom: 20px;
		letter-spacing: 1px;
	}
	.subtitle{
		font-size: 18px;
	}
	.btn-socmed{
		font-size: 16px;
		font-family:'proximanova.light';
		padding: 10px 15px;
		border: 1px solid #595959;
		margin-left: 10px;
	}
	.ig__thumb--list{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-right: -5px;
		margin-top: 36px;
		margin: -30px -6px -6px;
		.ig__thumb--item{
			display: block;
			width: calc(16% - 6px);
			padding: 3px;
			.img{
				position: relative;
				width: 100%;
				height: 100%;
			}
			a{
				width: 100%;
				height: 0;
				padding-bottom: 100%;
				display: block;
			}
			img{
				width: 100%;
			    display: block;
			    position: absolute;
			    height: 100%;
			    object-fit: cover;
			}
		}
	}
}

// responsive
@include break (1024){
	.home__type{
		.col{
			&--left{
				width: 68%;
				&:hover{
					.col__box{ 
						width:70%; height:60%; 
						.btn--box__small{ bottom:0px; }
					}
					.home__type__desc{
						transition:0 ease; opacity: 0; visibility: hidden; height:0;
					}
				}
			}
			&--right{ 
				width: 32.5%;
				margin-left: 2%;
			}
			&__box{
				display: flex;
				align-items: center;
				width:70%; height:60%; 
				background: rgba(255, 255, 255, 0.7);
				.btn--box__small{ position:relative; bottom:0px; }
				&__wrapper{
					padding:0;
				}
			}	
			&__title{
				font-size: 19px;
			}
		}
		&__desc{ opacity: 0; visibility: hidden; height:0; }
	}
}
@include break (980){
	.home__subscribe{
		// flex-flow:row wrap;
		flex-direction: column;
		.input__wrapper{
			margin-left:0px;
		}
	}
	.home__editorpick{
		.product__tagdiscount{
			top: 0;
			bottom: initial;
		}
	}
	.home__event{
		.event{
			&__content{
				width:280px;
				&__wrapper{
					padding: 0 20px;
				}
			}
			&__title{
				font-size: 36px;
			}
			&__desc{
				font-size: 22px;
				width: 100%;
			}
			&__hr{
				display: block;
				width: 45px;
				border:none;
				border-top:2px solid $grey6;
				margin: 15px auto 24px auto;
			}
		}
	}
}

@include break (768){
	.home__type{
		.col{
			&--left{
				&:hover{
					.col__box{ 
						// .btn--box__small{ bottom:20px; }
					}
				}
			}
			&__box{
				// .btn--box__small{ bottom:20px; }
			}
		}
	}

	.ig__section{
		.ig__thumb--list{
			.ig__thumb--item{
				width: calc(33.33% - 6px);
				&:nth-child(n+10){
					display: none;
				}
			}
		}
	}
}

@include break (600){
	.home__subscribe{
		margin-bottom:20px;
	}
	.home__editorpick{
		margin-top:20px;
	}
	.home__type{
		display: inline-block;
		.col{
			&--left{ width:49%; max-height: 338px; overflow: hidden;}	
		}
		// &__desc{ display: none; }
	}
	.home__event{
		// &:after{
		// 	visibility: visible;
		// 	opacity: 1;
		// }
		.event{
			&__content{
				width:40%;
				// &--right{ left:0; }
				// &--left{ right:0; }
			}
			&__title{ 
				font-size: 34px;
				span:first-child{ margin-right:5px; }
				// br{ display: none; } 
			}
			&__desc{ display: none; }
			&__hr{ display: none; }
		}
	}
}

@include break (480){
	.home__event{
		.event{
			&__title{ font-size: 16px; }
			&__content{
				width:46%;
				&__wrapper{
					padding: 0;
				}
			}
		}
	}
	.home__editorpick{
		.btn--main{
			display: block;
			margin: 0;
		}
	}
	.home__type{
		.col{
			&__box{
				.btn--box__small{     margin-top: 10px; }
			}
		}
	}
	.ig__section{
		.title{font-size: 23px;}
		.subtitle{
			font-size: 12px;
		}
		.btn-socmed{font-size: 12px;}
		.ig__thumb--list{
			.ig__thumb--item{
				width: calc(50% - 6px);
				&:nth-child(n+7){
					display: none;
				}
			}
		}
	}
}

@include break (320){
	.home__event{
		.event{
			&__title{ font-size: 19px; }
		}
	}
	.home__type{
		.col{
			&--left{ 
				width:100%; margin:5px auto;
				&:hover{
					.col__box{ 
						// .btn--box__small{ bottom:80px; }
					}
				} 
			}	
			&__box{
				// &__wrapper{ padding: 60px 15px 25px 15px; }
				// .btn--box__small{ bottom:80px; }
			}
		}
		&__desc{ display: block; }
	}
}


@include breakhome (portrait){
	.home__type{
		&__desc{ display: none; }
	}
}
@include breakhome (landscape){
	.home__type{
		&__desc{ display: block; }
	}
}