.wells{
    padding: 10px 20px;
    background:#ebebeb;
    &--left{
    	display: inline-block;
    	width:50%;
    	text-align: left;
    }
    &--right{
    	display: inline-block;
    	width: 47%;
    	text-align: right;
    }
}

@include break (768){
	.wells{
		h5{ font-size: 14px; }
	}
}