.product{
	display: inline-block;
	position: relative;
	// width:310px;
	max-width:100%;
	// margin: 10px 0 20px 0;
	float: left;
	width: 31.3%;
	margin: 10px 0 20px 0;
	&__wrapper{
		margin:0 0 40px 0;
    	// display: inline-block;
    	width: 100%;
    	justify-content: flex-start;
	}
	&__img{		
		position: relative;
	}
	&__desc{
		width:calc(100% - 70px);
		margin:20px 0 0 0;
	}
	&__link{
		margin:25px 0 0 0 !important;
	    z-index: 2;
	}
	&__title{
		// width:240px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size:17px;
	}
	&__filter{
		&--top{margin: 0 1%;}
		&--bottom{margin: 0 1%;}
	}
	&__tagspecial{
		position: absolute;
		top:10px; left:10px;
		width:50px; height:30px;
		background:#39b54a;
		border-radius:50%;
	    font-family: 'proximanova.bold';
		font-size: 13px;
		color:white;
		line-height: 16px;
		letter-spacing: 0;
		padding: 20px 10px;
		z-index: 1;
	}
	&__tagdiscount{
		@extend .product__tagspecial;
		background: #fc6d8a;
	}
}
.products__allflowers{
	.product{
		margin: 1% 1% 2% 1%;
	}
}

.product-detail{
	&__preview{
		&-wrapper{ 
			position: relative; 
			.product__tagspecial,
			.product__tagdiscount{
			}
		}
	}
	&__desc-header{}
	&__mdesc-header{ display: none; }
	&__mdesc{}	
	&__desc{
	    box-sizing: border-box;
	    padding:0 20px;
	    &-wrapper{
	    }
		.price__wrapper{
			.price__nominal{ 
				display: inline-block;
				margin-right:5px;
			}
			.price__tag{
				display: inline-block;
				margin:0 5px 0 0;
				padding:2px 8px;
				color:white;
				&.special{ background:#39b54a; }
				&.discount{ background:#fc6d8a; }
				
			}
			.price__discount{
				display: inline-block;
				margin:0 5px 0 0;
				color:$grey3;
				text-decoration: line-through;
			}
		}		
		&__ul{
			// width:100%;
			margin:10px 0;
			display: block;
			.column-label{ 
				position: relative;
				display: inline-block;
				min-width:60px; 
				// &:before{
				// 	content:":";
				// 	position:absolute;
				// 	top:0; bottom:0; right:0;
				// }
			}
			.column-content{
			    // display: inline-table;
			    width: calc(100% - 75px);
			    // float:right;
			    display: inline-block;
			    vertical-align: text-top;

			}
		}
		.countdown{
			position: relative;
			display: inline-block;
    		width: 100%;
			color:$grey4;
			margin: 0 0 20px 0;
			background:#f5f5f5;
			&__times{
				float:left;
			}
			&__hour{
				position: relative;
				float: left;
			    text-align: center;
				&:after{
					content:":";
					position: absolute;
					top:14px; right:-7px;
					margin:auto;
					height:15px;
				}
			}
			&__min{
				float:left;
			    margin: 10px 0 0 10px;
			}
			&__info{
				float: left;				
				position: absolute;
				top: 0; bottom: 0;
				margin: auto 0 auto 15px;
				color: #9fa197;
				letter-spacing: 0;
				height: 15px;				
			}
			&__text{
				display: block;
    			text-align: center;
				font-size: 10px;
			}
			.hours{
				font-size:32px;
				font-weight:600;
			}
			.minutes{
				font-size:19px;
				line-height: 27px;
			}
		}
	}
	&__pack{
		cursor:pointer;
		width:100px;
		margin:0 8px 14px 0;
		.pack__img{
			width:100px;
			height:100px;
			margin-bottom:8px;
		}
		.pack__title{
			width: calc(100% - 17px);
			display: inline-table;
			color:$grey6;
			margin-bottom:5px;
			font-size:11px;
			span{ font-size:13px; }
		}
		::-webkit-input-placeholder { font-style: italic; }
		::-moz-placeholder { font-style: italic; } /* firefox 19+ */
		:-ms-input-placeholder { font-style: italic; } /* ie */
		input:-moz-placeholder { font-style: italic; }
		&#special-wrap{
			display: none;
		}
		input[type=radio]{
			width: 13px;
		}
	}
}
.zoomWindow{
  margin-left:25px;
}
.product__detail__size{
	.chosen-single{
		width: 230px !important;
	}
}
.products__allflowers{
	h2{
		margin: 0 1% 15px 0%;
	}
	// .product__filter--top{
	// 	margin: 0 1%;
	// }
	// .product__filter--top{
	// 	margin: 0 1%;
	// }
}
.bxlider--m--overlay{
	display: none;
}
.delivery__available {
    font-size: 11px;
    letter-spacing: 0.1px;
    margin: 15px 0 10px 0;
    display: block;
}
#quantity{
	border: 1px solid #363636;
	border-right: 0px solid transparent;
	border-left: 0px solid transparent;
	&:disabled{
		background-color: #fff;
	}
}
.special-wrap{
	textarea.small{
		height: 80px;
	}
}
// #gift-card-note {
//     margin: 0 0 5px 0;
// }
// responsive
@include break (1024){
	.product{		
		// &__wrapper{ display: inline-block; }
		// &__title{ width:150px; }
		&__filter{
			&--top{
				display: block;
				.showpage{ display: none; }
			}
			&--bottom{
				.showpage{ display: none; }
				.sortpage{ display: none; }
			}
		}
	}
	.product-detail{
		&__desc{
			.countdown{ display: none; }
		}
		&__preview{
			&-wrapper{ 
				
			}
		}
	}
}
@include break (980){
	.product{
		float:left;		
		overflow: hidden;
		width:32%;
		margin: 1% 1% 2% 0;
		// &:first-child{ margin:1% 0 2% 0}
		&__desc{
			margin:2% 0 0 0;
			width:100%;
		}
		&__title{
			width:100%;
		}
		&__link{
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			margin: 0 !important;
			text-indent: 9000px;
			border: 0 !important;
			&:hover{ background:transparent !important; }
		}
		&__desc{
			.product__title{ letter-spacing: 0; }
			p{}
		}
		&__tagspecial{
			position: absolute;
			top:initial; left:0; top:0;
			width:100%; height:initial;
			border-radius:0;
			font-family: 'gotham.medium'; 
			font-size: 14px;
			padding: 5px 0;
			z-index: 1;
		}
	}
	.product-detail{
		&__preview{
			&-wrapper{ 
				width:100%;
				margin-bottom:10px;
				position: relative; 
				.product__tagspecial,
				.product__tagdiscount{
					top:0;
					bottom:initial;
				}
			}
		}
		&__desc{
			padding:0;
			h2{ margin: 0 0 10px 0 }
		    &-wrapper{ width:100%; margin-bottom:30px; }
		    .price__wrapper{
		    	.price__nominal { font-size: 16px }
				.price__tag{ display: none; }
			}
		}
		&__desc-header{ display: none; }
		&__mdesc-header{ display: block; }
		&__preview{
			&-wrapper{ 
				.product__tagspecial,
				.product__tagdiscount{
					width:450px;
				}
			}
		}
	}
	.zoomContainer{ display: none !important; }
	.products__allflowers{
		h2, .product__filter--top{
			margin: 0 2% 0 1%;
		}
		.product{
			margin: 1% 1% 2% 1%;
			width: 31%;
		}
	}
}
@include break (768){
	.product{
		&__wrapper{ margin:0 0 20px 0; }
		&__tagspecial{ padding:0; font-size: 11px; letter-spacing:0; }
		&__desc{
			.product__title{ font-size: 14px; letter-spacing: 0; }
			p{ font-size: 12px; }
		}
	}
	.product-detail{
		&__mdesc{
			text-align: center;
		}
		&__desc{
			hr { display: none; }
		    .price__wrapper{
		    	display: inline-block;
	    	    margin-bottom: 5px;
		    	.price__nominal { font-size: 16px }
				.price__tag{ display: none; }
			}			
		}
		&__pack{
			width:90px;
			.pack__img{ width:90px; height:90px; }
			// .pack__title{ width: calc(100% - 17px); }
			input{ position: relative; bottom: 1px; }
		}
		&__preview{
			&-wrapper{  
				.product__tagspecial,
				.product__tagdiscount{
					width:100%;
					max-width: 450px;
					left: 0;
					right: 0;
					margin: auto;
					transition: 0.4s ease !important;
				}
			}
		}
	}
	.bxlider--m--overlay{
		width: 100%;
		height: calc(100% - 65px);
		position: absolute;
		top: 0;
		z-index: 3;
		display: block;
	}
}
@include break (600){
	.product{
		width:48%;
		margin: 2% 2% 2% 0;
		// &:first-child{
		// 	margin: 2% 0 2% 0;
		// }	
	}
}
@include break (480){
	.product-detail{
		&__pack{
			width: 33.3%;
			margin: 0 0px 14px 0;
			padding: 0 5px 0 5px;
			.pack__img{ width: 100%;height: auto; }
		}
	}
}
@include break (400){
	.product-detail{
		&__pack{
			width: 50%;
		}
	}
}
@include break (320){
	.product{
		width:98%;
		margin: 2% 0 7% 0;
		&:first-child{
			margin: 2% 0 7% 0;
		}		
	}
}