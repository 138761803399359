.text-center{text-align: center;}
.text-left{text-align: left;}
.text-right{text-align: right;}
.text-justify{text-align: justify;}
body {
    color: white;
    font-size: 15px;
    letter-spacing: 0.8px;
    font-family:'proximanova.regular', arial, sans-serif;
    color:$grey6;
}
h1 {
    font-size: 30px;
    font-weight:normal;
    line-height: normal;
    margin:0;
    padding:0;
}
h2 {
    font-family:'proximanova.semibold';
    font-size: 28px;
    font-weight:normal;
    letter-spacing: 1.8px;
    margin:0 0 15px 0;
    padding:0;
}
h3 {
    font-family:'proximanova.light';
    font-size: 24px;
    margin:0 0 12px 0;
    padding:0;
    font-weight:normal;
}
h4 {
    font-family:'proximanova.semibold';
    font-size: 20px;
    letter-spacing: 1.2px;
    margin:0;
    padding:0;
}
h5 {
    font-size: 18px;
    margin:0;
    padding:0;
}
h6 {
    font-size: 16px;
    margin:0;
    padding:0;
}
p{
    font-size: 15px;
    line-height:22px;
    letter-spacing: 0;
}
a {
    font-size: inherit;
    color:inherit;
    text-decoration: none;
    &.link{
        @extend .bold;
        &:hover{
            text-decoration: underline;
        }        
    }
}
label{
    color:$grey5;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
}
small {
    font-size: 13px;
    line-height: 20px;
}
hr{
    display: inline-block;
    width: 100%;
    border:none;
    border-top:1px solid $grey1;
    margin: 15px 0;
}
ul{
    display: inline-block;
    list-style:none;
    padding:1em 0;
    margin:0;     
    li{
        a{ 
            text-decoration: none;
            img{
                float:left;
            }
        }
    }
    &.disc{
        list-style-type: disc;
        padding: 8px 0 8px 18px;
        li{
            line-height: 130%;
            margin: 5px 0;
        }
    }
}
nav{
    ul{
        display: inline-block;
        margin:0 auto;
        padding:0;
        li{
            display:inline-block;
            float:left;
        }
    }
}

// responsive
@include break (768){
    h1 {
        font-size: 27px;
    }
    h2 {
        font-size: 23px;
        letter-spacing: 0;
    }
    h3 {
        font-size: 21px;
    }
    h4{
        font-size: 19px;
    }
    h5{
        font-size: 17px;
    }
    h6{
        font-size: 15px;
    }
    p, a, label, li{
        font-size: 13px;
        line-height: 18px;
    }
}


